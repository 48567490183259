import { Signal } from "@preact/signals";

export default function ResizeSlider(
  { leftPanelWidth }: { leftPanelWidth: Signal<number> },
) {
  // Function to handle slider movement
  const handleSliderChange = (e: Event) => {
    const newWidth = (e.target as HTMLInputElement).valueAsNumber;
    leftPanelWidth.value = newWidth; // Update the signal directly

    // Adjust the panels according to the slider position
    const leftPanel = document.getElementById("left-panel");
    const rightPanel = document.getElementById("right-panel");
    if (leftPanel) {
      leftPanel.style.width = `${newWidth}%`; // Set left panel to newWidth
    }
    if (rightPanel) {
      rightPanel.style.width = `${100 - newWidth}%`; // Set right panel to 100 - newWidth
    }
  };

  return (
    <div class="flex justify-center items-center w-full">
      {/* Slider */}
      <input
        type="range"
        min="20" // Minimum width 20%
        max="80" // Maximum width 80%
        value={leftPanelWidth.value} // Current width controlled by signal
        onInput={handleSliderChange} // Handle slider changes
        class="w-64 h-2 bg-gray-300 rounded-full cursor-pointer"
      />
    </div>
  );
}
